.job-guarantee-details-header{
  margin-top: 120px !important;
  width: 90%;
  margin: auto;
  max-width: 1200px;
  .my-learnings-flex{
    display: flex;
    align-items: center;
  }
  .learnings-text{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    display: flex;
    align-items: center;
    font-feature-settings: 'ss03' on;
    color: #0F1113;
    margin-top: -4px;
    margin-left: 11px;
    opacity: 0.6;
  }
  .c-h-program-details{
    margin-top: 60px;
    display: flex;
    .learning-course-intro{
      margin-left: 32px;
    }
    .learning-course-intro h3{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #0F1113;
    }
    .image-text{
      margin-left: 10px;
    }
    .like-btn{
      border: 1px solid black;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
    }
    .c-h-course-card-header{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #0F1113;
      margin-left: 38px;
    }
    .c-h-course-card{
      background: linear-gradient(135deg, #2A5673 14.64%, #1D3557 85.36%);
      border-radius: 16px;
      width: 310px;
      height: 320px;
      overflow: scroll;
      padding: 15px;
      margin-top: 23px;
    }
    .c-h-course-card::-webkit-scrollbar{
      display: none;
    }
    .c-h-course-card p{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      font-feature-settings: 'ss03' on;
      color: #F1F2F4;
      margin-left: 9px;
    }
    .c-h-course-card h5{
      font-family: 'Urbanist';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      font-feature-settings: 'ss01' on, 'ss03' on;
      color: #FFFFFF;
      margin-top: 13px;
    }
    .c-h-course-card h2{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      margin-top: 16px;
    }
    .apply-now-btn{
      background: linear-gradient(135deg, #FF9494 14.64%, #FF7070 85.36%);
      border-radius: 8px;
      width: 100%;
      height: 56px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      border: none;
      margin-top: 16px;
    }
    .archive-like-btn{
      display: flex;
    }
  }
  .c-h-program-links{
    display: flex;
    margin-top: -70px;
  }
  .active-header{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    color: #0F1113;
  }
  .about-courses{
    margin-top: 48px;
  }
  .about-courses h3{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    background: linear-gradient(135deg, #2A5673 14.64%, #1D3557 85.36%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .about-courses p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
    font-feature-settings: 'ss03' on;
    color: #0F1113;
    margin-top: 16px;
    width: 65%;
  }
  .about-courses-ul{
    margin-left: 30px;
    margin-top: 16px;
  }
  .about-courses-ul li{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #0F1113;
    padding-top: 10px;
  }
  .c-i-content-collapse{
    border: 1px solid #F1F2F4;
    border-radius: 8px;
    width: 65%;
  }
  .title{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0F1113;
  }
  .title-answer{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #0F1113;
  }
  .c-h-mentor-card{
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  .c-h-mentor-inner-card{
    margin-right: 30px;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    box-shadow: 0px 8px 16px rgba(15, 17, 19, 0.04);
    border-radius: 16px;
    width: 259.78px;
    margin-top: 10px;
  }
  .c-h-mentor-card-img{
    width: 50%;
    margin: auto;
    }
  .c-h-mentor-card h1{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #0F1113;
    text-align: center;
  }
  .c-h-mentor-card h4{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #0F1113;
    text-align: center;
  }
  .c-h-rating-flex{
    display: flex;
    width: 65%;
    align-items: center;
  }
  .c-h-rating-bar{
    display: flex;
    align-items: center;
    margin-top: -10px;
  }
  .c-h-rating-bar img{
    margin-left: 5px;
    position: relative;
    top: 8px;
  }
  .rating-point{
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #0F1113;
  }
  .rating-score{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 5px;
  }
  .c-h-rating-left{
    margin-right: 40px;
  }
  .c-h-rating-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    margin-top: 30px;
  }
  .c-h-user-inner{
    display: flex;
    align-items: center;
  }
  .c-h-user-name{
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .c-h-user-time{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #A9A9A9;
  }
  .c-h-user-review-description{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
  }

}

@media screen and (max-width: 1023px) {

  .c-h-program-details{
    display: flex;
    flex-wrap: wrap;
  }
    .c-h-program-course{
      width: 80px;
    }
  .learning-course-intro{
    margin-left: 12px !important;
  }
  .c-h-course-card-header h2{
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #0F1113;
    margin-top: 30px;
  }
  .c-h-program-links{
    margin-top: 30px !important;
    overflow: scroll;
  }
  .about-courses{
    width: 100% !important;
  }
  .about-courses p{
    width: 100% !important;
  }
  .c-h-mentor-card{
    display: flex;
    overflow: scroll;
    flex-wrap: nowrap !important;
  }
  .c-h-mentor-inner-card{
    padding:30px;
  }
  .c-h-mentor-card-img{
    width: 100% !important;
  }
  .c-h-mentor-card-img::-webkit-scrollbar{
    display: none !important;
  }
  .c-i-content-collapse{
    width: 98% !important;
  }
  .c-h-feedback-certificate{
    width: 95% !important;
  }
  .c-h-rating-flex{
    width: 100% !important;
  }
  .c-h-rating-user{
    width: 98% !important;
  }

  .c-h-course-card-header{
    margin-left: 0px !important;
  }
  .learning-course-intro{
    width: 50% !important;
  }
  .c-h-program-links::-webkit-scrollbar{
    display: none;
  }
}

@media screen and (min-width: 930px) and (max-width: 1024px) {
  .my-learnings-flex{
    padding-top: 30px;
  }
  .c-h-program-details{
    display: flex;
    flex-wrap: wrap;
  }
  .c-h-program-links{
    margin-top: 30px !important;
  }
}