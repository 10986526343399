.collapse-container {
  width: 100%;
  border: 1px solid var(--primary--light);
  border-radius: 8px;
  margin: 8px 0 8px;
  > .collapse-title {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
  > .collapse-body {
    padding: 0 16px 16px;
    height: 0px;
    display: none;
    transition: height 0.5s;
    &.show {
      display: block;
      height: auto;
    }
    ul,
    ol {
      padding-left: 16px;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
    line-height: 20px;
    > .collapse-title {
      padding: 10px;
      font-size: 10px;
      line-height: 14px;
    }
    > .collapse-body {
      ul,
      ol {
        padding-left: 10px;
      }
    }
  }
}
