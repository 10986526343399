.app {
    padding: 0px;
    box-sizing: border-box;
}

:root {
    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Urbanist:wght@300;400;500;600;700;800;900&display=swap');

    body {
        margin: 0;
        font-family: 'Urbanist', Manrope, 'Roboto', Helvetica, 'Monsarate';
        color: var(--primary--dark);
        margin: 0;
        padding: 0;
        background: #F2F2F2;
        overflow: auto ;
    }

    img {
        user-select: none;
    }
    .ck.ck-editor__editable_inline{
        min-height: 200px !important;
        ul,ol{
            padding-left: 20px !important;
        }
    }

    :root {
        /* Color styles */
        --primary--dark: #0f1113;
        --primary--light: rgba(241, 242, 244, 1);
        --white: rgba(255, 255, 255, 1);
        --primary: rgba(255, 112, 112, 1);
        --secondary: rgba(70, 153, 151, 1);

        /* Primary Gradient */
        --primary-gradient: linear-gradient(135deg, #ff9494 14%64%, #ff7070 85%36%);

        /* BG Gradient */
        --bg-background: linear-gradient(180deg,
                #ffffff 0%,
                #ffffff 37%5%,
                #f1f2f4 100%);

        /* Tertiary Gradient */
        --tr-background: linear-gradient(135deg, #2a5673 14%64%, #1d3557 85%36%);

        // sizes
        --size: 4px;
        --size-1x: 4px;
        --size-2x: 8px;
        --size-3x: 12px;
        --size-4x: 16px;
        --size-5x: 20px;
        --size-6x: 24px;
        --size-7x: 28px;
        --size-8x: 32px;

        // Content size
        --content-widht: 1174px;

        // Shadow

        --shadow-primary: 0px 4px 8px #f1f2f4;
        --shadow-secondary: 0px 4px 8px 0px #0000000a;
        --shadow-alternate: 0px 8px 16px 0px #0f11130a;
    }

    // Font varients
    %text-style-manrope-3-xl-semibold {
        font-size: 32px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 40px;
        text-decoration: none;
    }

    %text-style-manrope-2-xl-regular {
        font-size: 24px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-manrope-2-xl-bold {
        font-size: 24px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-manrope-2-xl-medium {
        font-size: 24px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-manrope-xl-semibold {
        font-size: 20px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 28px;
        text-decoration: none;
    }

    %text-style-manrope-xl-medium {
        font-size: 20px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 28px;
        text-decoration: none;
    }

    %text-style-manrope-lg-light {
        font-size: 18px;
        font-family: 'Manrope';
        font-weight: lighter;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-manrope-base-light {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: lighter;
        font-style: normal;
        line-height: 28px;
        text-decoration: none;
    }

    %text-style-manrope-base-regular {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-manrope-base-medium {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-manrope-base-bold {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-manrope-base-var-semibold {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-base-var-regular {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-base-var-medium {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-base-var-light {
        font-size: 16px;
        font-family: 'Manrope';
        font-weight: lighter;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-sm-regular {
        font-size: 14px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-manrope-sm-bold {
        font-size: 14px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-manrope-sm-var-regular {
        font-size: 14px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-xs-regular {
        font-size: 12px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-manrope-xs-medium {
        font-size: 12px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-xs-semibold {
        font-size: 12px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-manrope-xs-light {
        font-size: 12px;
        font-family: 'Manrope';
        font-weight: lighter;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-manrope-2-xs-var-regular {
        font-size: 10px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    %text-style-manrope-2-xs-var-bold {
        font-size: 10px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-2-xs-var-semibold {
        font-size: 10px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    %text-style-manrope-2-xs-var-light {
        font-size: 10px;
        font-family: 'Manrope';
        font-weight: lighter;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-manrope-2-xs-regular {
        font-size: 10px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-manrope-3-xs-regular {
        font-size: 8px;
        font-family: 'Manrope';
        font-weight: normal;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    %text-style-manrope-3-xs-medium {
        font-size: 8px;
        font-family: 'Manrope';
        font-weight: bold;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    %text-style-manrope-3-xs-bold {
        font-size: 8px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    %text-style-manrope-4-xl-bold {
        font-size: 48px;
        font-family: 'Manrope';
        font-weight: bolder;
        font-style: normal;
        line-height: 64px;
        text-decoration: none;
    }

    %text-style-urbanist-4-xl-bold {
        font-size: 48px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 64px;
        text-decoration: none;
    }

    %text-style-urbanist-3-xl-bold {
        font-size: 32px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 48px;
        text-decoration: none;
    }

    %text-style-urbanist-2-xl-bold {
        font-size: 24px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-urbanist-xl-bold {
        font-size: 20px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 32px;
        text-decoration: none;
    }

    %text-style-urbanist-xl-medium {
        font-size: 20px;
        font-family: 'Urbanist';
        font-weight: bold;
        font-style: normal;
        line-height: 28px;
        text-decoration: none;
    }

    %text-style-urbanist-lg-bold {
        font-size: 18px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-urbanist-base-bold {
        font-size: 16px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-urbanist-sm-semibold {
        font-size: 14px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-urbanist-sm-black {
        font-size: 14px;
        font-family: 'Urbanist';
        font-weight: 900;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-urbanist-xs-medium {
        font-size: 12px;
        font-family: 'Urbanist';
        font-weight: bold;
        font-style: normal;
        line-height: 24px;
        text-decoration: none;
    }

    %text-style-urbanist-xs-black {
        font-size: 12px;
        font-family: 'Urbanist';
        font-weight: 900;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-urbanist-xs-var-medium {
        font-size: 12px;
        font-family: 'Urbanist';
        font-weight: bold;
        font-style: normal;
        line-height: 20px;
        text-decoration: none;
    }

    %text-style-urbanist-2-xs-bold {
        font-size: 10px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-urbanist-2-xs-medium {
        font-size: 10px;
        font-family: 'Urbanist';
        font-weight: bold;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-urbanist-2-xs-black {
        font-size: 10px;
        font-family: 'Urbanist';
        font-weight: 900;
        font-style: normal;
        line-height: 16px;
        text-decoration: none;
    }

    %text-style-urbanist-3-xs-bold {
        font-size: 6px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 8px;
        text-decoration: none;
    }

    %text-style-urbanist-3-xs-semibold {
        font-size: 8px;
        font-family: 'Urbanist';
        font-weight: bolder;
        font-style: normal;
        line-height: 12px;
        text-decoration: none;
    }

    .common-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        align-items: center;

        h2 {
            font-family: Urbanist;

            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
        }

        >span {
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: 480px) {
            h2 {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    .nav-btn {
        margin-left: 32px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-radius: var(--size-2x);
        border: 1px solid var(--primary--dark);

        @media screen and (max-width: 480px) {
            margin-left: 8px;
            min-height: 24px;
            height: 24px;
            min-width: 24px;
            width: 24px;

            img {
                height: 14px;

                width: 14px;
            }
        }

        &:disabled {
            filter: invert(0.9);
        }
    }

    button {
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .primary-btn {
        height: auto;
        border: none;
        padding: 12px 16px;
        background: var(--primary--dark);
        border-radius: 8px;
        font-family: Manrope;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        height: fit-content;
        width: fit-content;
        flex-shrink: 0;
        color: #fff;
        cursor: pointer;

        &.gradient-bg {
            background: var(--primary-gradient);
            color: #fff;
        }

        @media screen and (max-width: 480px) {
            font-size: 10px;
            line-height: 12px;
            padding: 8px 12px;
        }

        &:disabled {
            background: var(--secondary);
            cursor: not-allowed;
        }
    }

    .hide-section {
        display: none !important;
    }

    // Custom Scroll bar for Desktop
    @media screen and (min-width: 800px) {

        /* width */
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 5px var(--primary--light);
            border-radius: 10px;
            background: white;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--secondary);
            border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--tr-background);
        }
    }

    /* Color styles */
    --primary--dark: #0f1113;
    --primary--light: rgba(241, 242, 244, 1);
    --white: rgba(255, 255, 255, 1);
    --primary: rgba(255, 112, 112, 1);
    --secondary: rgba(70, 153, 151, 1);

    /* Primary Gradient */
    --primary-gradient: linear-gradient(135deg, #ff9494 14%64%, #ff7070 85%36%);

    /* BG Gradient */
    --bg-background: linear-gradient(180deg,
        #ffffff 0%,
        #ffffff 37%5%,
        #f1f2f4 100%);

    /* Tertiary Gradient */
    --tr-background: linear-gradient(135deg, #2a5673 14%64%, #1d3557 85%36%);

    // sizes
    --size: 4px;
    --size-1x: 4px;
    --size-2x: 8px;
    --size-3x: 12px;
    --size-4x: 16px;
    --size-5x: 20px;
    --size-6x: 24px;
    --size-7x: 28px;
    --size-8x: 32px;

    // Content size
    --content-widht: 1174px;

    // Shadow

    --shadow-primary: 0px 4px 8px #f1f2f4;
    --shadow-secondary: 0px 4px 8px 0px #0000000a;
    --shadow-alternate: 0px 8px 16px 0px #0f11130a;
}

input,textarea{
    font-family: Manrope;
}